import React, {FC, useEffect, useState} from 'react';
import SignalsComponent from '../components/Signals/SignalsComponent';

const useTimer = (): [number, boolean, boolean, number, boolean, boolean] => {
    const [count, setCount] = useState(0);
    const [soundBeepOrBgm, setSoundBeepOrBgm] = useState(true);
    const [repeat, setRepeat] = useState(false);
    const [countUp, setCountUp] = useState(200);
    const [countUpStart, setCountUpStart] = useState(false);
    const [playing, setPlaying] = useState(false);

    const tick = () => {
        return setInterval(function () {
            if (count < 6) {
                setCount(prevCount => prevCount + 1);
            }

            switch (count) {
                case 0:
                    setPlaying(true);
                    break;
                case 5:
                    setPlaying(false);
                    setSoundBeepOrBgm(false);
                    setPlaying(true);
                    setRepeat(true);
            }

        }, 1000);
    };

    const msTick = () => {
        return setInterval(function () {

            if (count === 6) {
                setCountUpStart(true);
            }

            if (count >= 6) {
                setCountUp(prevCountUp => prevCountUp + 1.66);
            }
        }, 10);
    };

    useEffect(() => {
        const interval = tick();
        const msInterval = msTick();

        return () => {
            clearInterval(interval);
            clearInterval(msInterval)
        };
    });

    return [count, soundBeepOrBgm, repeat, countUp, countUpStart, playing];
};

const msToTIme = (ms: number): string => {
    const seconds = Math.floor(ms / 100);
    const milliseconds = Math.floor(ms - seconds * 100);
    const minutes = Math.floor(seconds / 60);
    const secondPer60 = seconds - minutes * 60;

    if (milliseconds < 10 && secondPer60 < 10) {
        return minutes + ':0' + secondPer60 + ':0' + milliseconds;
    }

    if (milliseconds < 10) {
        return minutes + ':' + secondPer60 + ':0' + milliseconds;
    }

    if (secondPer60 < 10) {
        return minutes + ':0' + secondPer60 + ':' + milliseconds;
    }

    return minutes + ':' + secondPer60 + ':' + milliseconds;
};

const signals = [
    {
        id: 1,
        name: 'firstSignal'
    },
    {
        id: 2,
        name: 'secondSignal'
    },
    {
        id: 3,
        name: 'thirdSignal'
    },
];

const SignalsContainer: FC = () => {
    const [count, soundBeepOrBgm, repeat, countUp, countUpStart, playing] = useTimer();

    return <SignalsComponent soundSelect={soundBeepOrBgm} repeat={repeat} count={count} signals={signals} countUp={countUp}
                             countUpStart={countUpStart} msToTime={msToTIme} playing={playing}/>;
};

export default SignalsContainer;