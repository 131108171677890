import {useState} from 'react';
import {createContainer} from "unstated-next";
import history from "./history";

const usePageTransitionManager = () => {
    const [clicked, setClicked] = useState(false);
    const [countUp, setCountUp] = useState(0);

    const willUnlock = () => {
        setTimeout(function () {
            setClicked(false);
        }, 1000)
    };

    const goToStart = () => {
        if (!clicked) {
            setClicked(true);
            willUnlock();

            setCountUp(0);
            history.push('/');
        }

        return false;
    };

    const goToSignals = () => {
        if (!clicked) {
            setClicked(true);
            willUnlock();

            history.push('/signals');
        }

        return false;
    };

    const goToResultWith = (countUp: number) => {
        if (!clicked) {
            setClicked(true);
            willUnlock();

            setCountUp(countUp);
            history.push('/result');
        }

        return false;
    };

    return {clicked, countUp, goToSignals, goToStart, goToResultWith};
};

export const PageTransitionManager = createContainer(usePageTransitionManager);
