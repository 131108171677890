import React, {FC} from 'react';

import './Signal.scss';

export interface SignalProps {
    id: number;
    name: string;
    colorIndex: string;
}

const SignalComponent: FC<SignalProps> =
    ({
         id,
         name,
         colorIndex
     }) => (
        <div key={id}>
            <div className={'signal ' + name + ' ' + colorIndex}/>
        </div>
    );

export default SignalComponent;