import React, {FC} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import Start from './components/Start/StartComponent';
import Result from './components/Result/ResultComponent';
import Signals from './containers/SignalsContainer';
import {PageTransitionManager} from "./PageTransitionManager";

import './App.scss';

const App: FC<{}> = () => (
    <PageTransitionManager.Provider>
        <div className="container">
            <Switch>
                <Route path="/signals" component={Signals}/>
                <Route path="/result" component={Result}/>>
                <Route path="/" component={Start}/>
                <Redirect to="/"/>
            </Switch>
        </div>
    </PageTransitionManager.Provider>
);

export default App;